import { ApplicationStatus } from '@appType/index';

export const PROD_APPLICATION_URL =
  'https://aion.westeurope.cloudapp.azure.com/integration/dev/api/v2/finca';
export const DEV_APPLICATION_URL =
  'https://capital-i.eastasia.cloudapp.azure.com/dev/api/v2/finca';

export const APPLICATION_URL = import.meta.env.DEV
  ? DEV_APPLICATION_URL
  : PROD_APPLICATION_URL;

export const ITEM_PER_PAGE: number = 5;

export const ROLES: Record<string, string> = {
  admin: 'Админ',
  user: 'Пользователь',
};

export const ADMIN_ROLE = 'admin';

// Credit scoring section fields
export const CREDIT_SCORING_STATUS: Record<string, string> = {
  rejected: 'Отказано',
  approved: 'Одобрено',
  approved_with_gurantee: 'Одобрено с гарантией',
};

export const CREDIT_SCORING_PURPOSE: Record<string, string> = {
  Services: 'Услуги',
  Trade: 'Торговля',
  Consumer: 'Потребительский',
  Agriculture: 'Сельское хозяйство',
  Production: 'Производство',
};

export const CREDIT_SCORING_CONSUMER: Record<string, string> = {
  Education: 'Образование',
  Personal: 'Личные',
  Furniture: 'Мебель',
  Residence: 'Жилье',
  Auto: 'Авто',
  Event: 'Мероприятия',
};

// Application STATUS
export const APPLICATION_STATUS: Record<ApplicationStatus, string> = {
  EMPTY: 'Нет статуса',
  PENDING: 'В обработке',
  REJECTED: 'Отказано',
  APPROVED: 'Выдано',
};

// CLIENT Routes
export const ROUTES = {
  LOGIN: '/login',
  APPLICATIONS: '/applications',
  DETAIL: '/detail/:id',
  USERS: '/users',
};

// API ROUTES
export const API_ROUTES = {
  LOGIN: '/login',
  APPLICATIONS: '/applications',
  APPLICATIONS_BY_ID: (id: number) => `/applications/${id}`,
  BANK_DECISION: '/bank_decision',
  GET_USER_INFO: 'users/info',
  ADD_USER: '/users',
  UPDATE_USER: '/users/update',
  BLOCK_UNBLOCK_USER: '/users/status/change',
  LIST_USER: '/users/list',
  SAVE_PASSPORT: (id: number) => `/documents/${Number(id)}`,
};

// LOCAL STORAGE KEYS
export const USER_DATA_KEY_LOCALSTORAGE = 'userData';
export const USER_TOKEN_KEY_LOCALSTORAGE = 'finca-token';

// CONTEXT ERRORS
export const AUTH_HOOK_CONTEXT_ERROR =
  'useAuth must be used within an AuthProvider';
export const ZOD_VALIDATION_ERROR =
  'Validation function without ZOD scheme is used';
export const MODAL_HOOK_CONTEXT_ERROR =
  'This component must be used within a <Modal/> component.';
export const LOAD_IMAGE_ERROR =
  'Не удалось загрузить фото паспорта, повторите позже';

// REGEX
export const HTML_TAG_REGEX = /<[^>]*>/g;
export const SPECIAL_CHARS: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#039;',
};
